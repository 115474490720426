<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea">
      <editHeader :isShow="isShow" :btn="btn" :strForm="popuForm" @openEdit="openEdit" @closeEdit="closeEdit"
        @submit="submit('popuForm')" />
      <div class="vd_export" v-if="isShow">
        <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
        <el-button type="primary" size="small" >打印预览</el-button>
      </div>
    </div>
    <el-form ref="popuForm" :model="popuForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
	    <el-row class="vg_mb_5">
		    <el-col :md="8">
			    <el-form-item label="采购经办人：" prop="popu_stff_name">
				    <el-input disabled v-model="popuForm.popu_stff_name" maxlength="30" show-word-limit placeholder="采购方名称"></el-input>
			    </el-form-item>
		    </el-col>
		    <el-col :md="8">
			    <el-form-item label="成品采购号：" prop="podr_no">
				    <el-input disabled v-model="popuForm.podr_no" maxlength="30" show-word-limit placeholder="成品采购号"></el-input>
			    </el-form-item>
		    </el-col>
		    <el-col :md="8">
			    <el-form-item label="供应商名称：" prop="tran_no">
				    <el-input disabled v-model="popuForm.supp_abbr" maxlength="30" show-word-limit placeholder="供应商名称"></el-input>
			    </el-form-item>
		    </el-col>
	    </el-row>
	    <el-row class="vg_mb_5">
		    <el-col :md="8">
			    <el-form-item label="采购日期：">
				    <el-date-picker
						    disabled
						    v-model="popuForm.popu_date"
						    size="small"
						    type="date"
						    placeholder="选择日期">
				    </el-date-picker>
			    </el-form-item>
		    </el-col>
		    <el-col :md="8">
			    <el-form-item label="要求交货日期：">
				    <el-date-picker
						    disabled
						    v-model="popuForm.deli_date"
						    size="small"
						    type="date"
						    placeholder="选择日期">
				    </el-date-picker>
			    </el-form-item>
		    </el-col>
		    <el-col :md="8">
			    <el-form-item label="客户订单号：" prop="tran_no">
				    <el-input disabled v-model="popuForm.popu_cust_no" maxlength="30" show-word-limit placeholder="客户订单号"></el-input>
			    </el-form-item>
		    </el-col>
	    </el-row>
	    <div class="vd_dash"></div>
	    <el-row class="vg_mb_5">
		    <el-col :md="24">
			    <PopuSubTable :popuForm="popuForm"></PopuSubTable>
		    </el-col>
	    </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="popuForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { popuAPI } from "@api/modules/popu";
import { cpttAPI } from "@api/modules/comptitle"
import {custAPI} from '@/api/modules/cust'
import editHeader from "@/views/component/editHeader";
import inputUser from "@/views/component/inputUser";
import PopuSubTable from "@/views/PurcManagement/PopuManage/TabChild/Component/PopuSubTable.vue";

export default {
  name: 'PopuEditMain',
  components: {
    editHeader,
    inputUser,
	  PopuSubTable
  },
  data() {
    return {
      rules: {
	      popu_stff_name: [{ required: true, trigger: 'blur', message: ' ' },],
      },
      popuForm: {
      },
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: false,
      popuPopueList: [
        {id:0,label:'可审核'},
        {id:1,label:'不可审核'},
      ],
      custOptionList:[],
      cpttList:[],
      dialogTableVisible:false,
      dialogTableVisible1:false,
      selectList:[],
      popuPartListPopu:[]
    }
  },
  created() {
    this.initData()
  },
  watch: {
  },
  methods: {
    initData() {
      this.loadingFlag = true
      this.getPopuInfo();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow)
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.$emit('isShow', this.isShow)
        this.initData()
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      }).catch(() => { })
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            this.$message.warning("请把必填内容补充完整");
            return false;
          }
        });
      }).catch(() => { })
    },
    // 保存
    saveInfo() {
      let popuForm = JSON.parse(JSON.stringify(this.popuForm))
	    post(popuAPI.editPopu, popuForm).then(res => {
		    if (res.data.code === 0) {
			    this.$message({
				    type: 'success',
				    message: '保存成功'
			    })
			    this.isShow = true;
			    this.$emit('isShow', this.isShow)
			    this.initData()
		    } else {
			    this.$message.error(res.data.msg);
			    this.initData()
		    }
	    });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData()
    },
	  
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getPopuInfo() {
	    get(popuAPI.getPopuById, {popu_id: this.$route.query.form_id}).then(res => {
		    if (res.data.code === 0) {
			    this.popuForm = res.data.data.form;
			    this.popuForm.deli_date *= 1000;
			    this.popuForm.popu_date *= 1000;
			    this.btn = res.data.data.btn;
			    this.stffForm.stff_name = this.popuForm.stff_name;
			    this.stffForm.dept_name = this.popuForm.dept_name;
			    this.stffForm.dept_team_name = this.popuForm.dept_team_name;
			    this.stffForm.user_id = this.popuForm.user_id;
			    this.stffForm.dept_id = this.popuForm.dept_id;
			    this.stffForm.stff_id = this.popuForm.stff_id;
			    // 回传后端destroy_flag时用到
			    this.popuPartListPopu = JSON.parse(JSON.stringify(this.popuForm.popu_prod_list))
			    setTimeout(() => {
				    this.loadingFlag = false
			    }, 500);
		    } else {
			    this.$message.error(res.data.msg);
		    }
	    })
    },
  }
}
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
.vd_word{
  word-wrap: break-word;
  word-break: normal; 
}
.vd_export2 {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	right: 150px;
}
</style>
