<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" ref="childTab1" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'">
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  // 自己确定文件路径
import PopuEditMain from "@/views/PurcManagement/PopuManage/TabChild/PopuEditMain";

export default {
  name: "PopuEdit",
  components: {
    childTab1: PopuEditMain,
  },
  data() {
    return {
      activeName: "first",
      isSho: true,
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    },
  },
};
</script>

<style scoped></style>
