<template>
	<div>
		<el-table :data="popuForm.popu_prod_list" border>
			<el-table-column label="序号" width="60" align="center">
				<template v-slot="scope">
					{{ scope.$index + 1 }}
				</template>
			</el-table-column>
			<el-table-column label="物料图片" align="center" width="100">
				<template v-slot="scope">
					<el-popover
							v-if="scope.row.imge_url"
							placement="right"
							width="200"
							trigger="hover">
						<el-image
								v-if="scope.row.imge_url"
								class="vd_elimg"
								:src="scope.row.imge_url"
								fit="fill">
						</el-image>
						<el-image
								slot="reference"
								v-if="scope.row.imge_url"
								class="vd_elimg"
								:src="scope.row.imge_url"
								fit="fill">
						</el-image>
					</el-popover>
					<span v-else class="vg_9f9a9a">暂无</span>
				</template>
			</el-table-column>
<!--			<el-table-column label="产品图片" width="200" align="center">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.cust_artno'">
						<el-button type="text">点击查看</el-button>
					</el-form-item>
				</template>
			</el-table-column>-->
			<el-table-column label="客户货号" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.cust_artno'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].cust_artno" disabled maxlength="30" show-word-limit
						          placeholder="暂无客户货号"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="我司货号" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.prod_no'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].prod_no" disabled maxlength="30" show-word-limit
						          placeholder="暂无我司货号"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="品名" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.prod_name'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].prod_name" disabled maxlength="30" show-word-limit
						          placeholder="暂无品名"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="中文规格" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.prod_spec'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].prod_spec" disabled maxlength="30" show-word-limit
						          placeholder="暂无中文规格"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="规格描述" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.prod_desc'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].prod_desc" disabled maxlength="20" show-word-limit
						          placeholder="暂无规格描述">
							<template #append>
								<el-popover
										placement="bottom"
										width="600"
										@show="textEnlargementShow(0,scope.$index)"
										trigger="click">
									<textEnlargement ref="textEnlargement" :disabledFlag="true"
									                 @textEnlargementChange="textEnlargementChange" :textEnlargementString="textEnlargementString"/>
									<span slot="reference"><el-link type="info">...</el-link></span>
								</el-popover>
							</template>
						</el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="订单最新数量" width="200">
				<template #header>
					<span class="vg_deep_red">订单最新数量</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.popu_prod_num'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].popu_prod_num" disabled maxlength="30" show-word-limit
						          placeholder="暂无订单最新数量"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="单位" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.prod_unit'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].prod_unit" disabled maxlength="30" show-word-limit
						          placeholder="暂无单位"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="内包装装量" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.popu_prod_qpb'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].popu_prod_qpb" disabled maxlength="30" show-word-limit
						          placeholder="暂无内包装装量"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="外箱装量" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.popu_prod_qpc'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].popu_prod_qpc" disabled maxlength="30" show-word-limit
						          placeholder="暂无外箱装量"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="外包装单位" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.popu_prod_unit'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].popu_prod_unit" disabled maxlength="30" show-word-limit
						          placeholder="暂无外包装单位"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="箱数" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.popu_prod_box'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].popu_prod_box" disabled maxlength="30" show-word-limit
						          placeholder="暂无箱数"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="外箱尺寸CM（长*宽*高）" width="200">
				<template #header>
					<span class="vg_deep_red">外箱尺寸CM（长*宽*高）</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.popu_prod_meas'"  :rules="{ required: true, message: ' ' }">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].popu_prod_meas"  maxlength="30" show-word-limit
						          placeholder="暂无外箱尺寸CM（长*宽*高）"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="每箱体积CBM" width="200">
				<template #header>
					<span class="vg_deep_red">每箱体积CBM</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.popu_prod_vol'" :rules="{ required: true, message: ' ' }">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].popu_prod_vol"  maxlength="30" show-word-limit
						          placeholder="暂无每箱体积CBM"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="总体积CBM" width="200">
				<template #header>
					<span class="vg_deep_red">总体积CBM</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.popu_prod_tvol'" :rules="{ required: true, message: ' ' }">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].popu_prod_tvol" maxlength="30" show-word-limit
						          placeholder="暂无总体积CBM"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="单价" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.popu_prod_price'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].popu_prod_price" disabled maxlength="30" show-word-limit
						          placeholder="暂无单价"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="金额" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.popu_prod_total'">
						<el-input v-model="popuForm.popu_prod_list[scope.$index].popu_prod_total" disabled maxlength="30" show-word-limit
						          placeholder="暂无金额"></el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="备注" width="200">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'popu_prod_list.' + scope.$index + '.popu_prod_remark'">
							<el-input v-model="scope.row.popu_prod_remark" disabled show-word-limit placeholder="暂无">
								<template #append>
									<el-popover placement="top" width="500" trigger="manual" v-model="scope.row.remarkShow">
											<el-input type="textarea" :rows="5" v-model="remark" clearable placeholder="请填写备注"></el-input>
											<div style="display: flex;margin-top: 10px">
												<el-button style="margin-left: auto" type="primary" size="mini" @click="saveRemark(scope.$index)">保存</el-button>
											</div>
										<el-button slot="reference" type="text" size="mini" @click="editRemark(scope.$index)">编辑</el-button>
									</el-popover>
								</template>
							</el-input>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="center" min-width="150px">
				<template v-slot="scope">
					<el-button class="vd_posi_c" plain size="mini" type="info" @click="changeRecord(scope.row)">变更记录</el-button>
					<el-button type="success" size="mini" plain>查看工艺单</el-button>
					<el-button type="primary" size="mini" plain>查看生产进度表</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!--变更记录提示框-->
		<el-dialog
				title="变更记录"
				:visible.sync="changeVisible"
				center
				width="50%">
			<div class="vg_mb_5" style="text-align:center;" v-for="(item,index) in recordChangeList" :key="item.popu_chan_id">
				{{++index}}.{{item.popu_chan_date | formatDate}}{{item.popu_chan_cont}}
			</div>
		</el-dialog>
	</div>
</template>

<script>
import textEnlargement from '@/views/component/textEnlargement.vue'
import helper from "@assets/js/helper";

export default {
	name: "PopuList",
	props: {
		popuForm: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			textEnlargementNum: 0,
			textEnlargementFlag: 0,
			textEnlargementString: {
				str: ''
			},
			multiSelection: [],
			custOptionList: [],
			visible: false,
			reVisible: false,
			remark: '',
			isFlag: true,
			changeVisible: false,
			recordChangeList: [],
		}
	},
	components: {
		textEnlargement
	},
	created() {
		this.initData()
	},
	filters: {
		// 时间转换
		formatDate(row) {
			return helper.toStringDate(row)
		},
	},
	methods: {
		initData() {
		},
		// 打开变更记录
		changeRecord(row) {
			if (row.popu_chan_list?.length > 0) {
				this.changeVisible = true;
				this.recordChangeList = row.popu_chan_list;
			} else {
				this.$message.warning("暂无变更记录!");
			}
		},
		// 多选
		handleSelectionChange(val) {
			this.multiSelection = [];
			this.multiSelection = val;
			this.$emit('handleSelectionChange', this.multiSelection)
		},
		
		// 框显示效果
		textEnlargementShow(val, val1) {
			this.textEnlargementFlag = val;
			this.textEnlargementNum = val1;
			if (val === 0) {
				this.textEnlargementString.str = this.popuForm.popu_prod_list[val1].prod_desc;
			}
			if (val === 1) {
				this.textEnlargementString.str = this.popuForm.popu_prod_list[val1].popu_prod_remark;
			}
		},
		// 更改值
		textEnlargementChange(val) {
			if (this.textEnlargementFlag === 0) {
				this.popuForm.popu_prod_list[this.textEnlargementNum].prod_desc = val
			}
		},
		saveRemark(index) {
			this.popuForm.popu_prod_list[index].popu_prod_remark = this.remark;
			this.popuForm.popu_prod_list[index].remarkShow = false;
		},
		editRemark(index) {
			this.remark = '';
			this.popuForm.popu_prod_list[index].remarkShow = true;
		},
	},
};
</script>

<style scoped lang="scss">
.vd_total {
	font-size: 24px;
	text-align: right;
	width: 100%;
	padding-right: 30px;
	box-sizing: border-box;
}

.vd_color {
	color: #c70e0e;
}
</style>
